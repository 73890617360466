<template>
  <div id="generalOrderCell">
    <el-row v-if="orderItem" class="generalOrderCellItem">
      <el-row class="cellOne">
        <el-col :span="3"><span>回购编号：{{ orderItem.repoId ? orderItem.repoId : '--' }}</span></el-col>
        <el-col :span="6" ><span>委托时间：{{ orderItem.createTime  | DateFormate }}</span></el-col>
        <el-col :span="6" class="espli"><span>回购方：{{ orderItem.firmEnterpriseName ? orderItem.firmEnterpriseName : '--'}}</span></el-col>
        <el-col :span="6" class="espli"><span>初始挂单方：{{ orderItem.memberEnterpriseName ? orderItem.memberEnterpriseName : '--'  }}</span></el-col>
         <el-col :span="3">
          <div class="viewDetailsButton">
            <el-button size="mini" @click="viewDetails(orderItem.repoId)">查看详情</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="cellTwo">
        <el-col :span="22">
          <el-col :span="3"><span>{{ orderItem.className ? orderItem.className : '--' }}</span></el-col>
          <el-col :span="4"><span>{{ orderItem.basisPrice ? orderItem.basisPrice : '--' }}元/{{orderItem.weightUnitName?orderItem.weightUnitName:'--' }}</span></el-col>
          <el-col :span="4"><span>{{ orderItem.repoWeight ? orderItem.repoWeight : '--' }}</span></el-col>
          <el-col :span="4"><span>{{ orderItem.storageName ? orderItem.storageName : '--' }}</span></el-col>
          <el-col :span="4"><span>{{ orderItem.repoWeight ? orderItem.repoWeight : '--' }}{{orderItem.weightUnitName?orderItem.weightUnitName:'--' }}</span></el-col>
          <el-col :span="4" ><span>{{ orderItem.repoStatus | repoStatusFormate }}</span></el-col>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
      <el-row class="cellThree">
        <el-col :span="1"><span>总计</span></el-col>
        <!-- <el-col :span="2"><span>数量：{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}</span></el-col> -->
        <el-col :span="2"><span>重量：{{ orderItem.repoWeight ? orderItem.repoWeight : '--' }}{{ orderItem.weightUnitName?orderItem.weightUnitName:'--' }}</span></el-col>
        <el-col :span="10" ><span>总价：<span class="redColor">&nbsp;{{ (orderItem.basisPrice * orderItem.repoWeight).toFixed(2) }}元</span></span>&emsp;</el-col>
        <!-- 撤单，付首款(订单状态已挂单 付款状态待付款) -->
        <!-- 买家付首款 --> 
        <el-col :span="10" class="cellMethod">
          <span v-if="orderItem.repoStatus === 'MU'||orderItem.repoStatus === 'FU'">订单已撤销</span>
          <el-button v-if="right.cancelOrder&&orderItem.repoStatus === 'W'&&orderItem.repoType==gzFlag" plain type="danger" size="mini" @click="undoRepoOrder(orderItem.repoType,orderItem.repoId)">撤销</el-button>
          <el-button v-if="orderItem.repoStatus === 'W'&&orderItem.repoType!=gzFlag" plain type="danger" size="mini" @click="agreeOrder(orderItem.repoType, orderItem.repoId)">同意</el-button>
          <el-button v-if="orderItem.repoStatus === 'W'&&orderItem.repoType!=gzFlag" plain type="danger" size="mini" @click="refuseOrder(orderItem.repoType, orderItem.repoId)">拒绝</el-button>
        </el-col>
      </el-row>
    </el-row>
  
    <!-- 订单详情 -->
    <orderDetails ref="orderDetails" :orderType="orderType" />
   
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import orderDetails from "./generalOrderDetails"
import md5 from "js-md5";


export default {
  props:{
    orderItem: Object,
    userType: String,
    gzFlag: String,
    orderType: String,
    right:Object
  },
  components: {
    orderDetails,
    
  },
  data(){
    return{
      timeOut:null,
      day:0,
      hour:'00',
      min:'00',
      second:'00',
      // right: {
      //   firmRepoOrder: false,
      //   memberRepoOrder: false,
      //   cancelOrder: false,
       
      // },
      dialogVisible: false,
      validButtonText: '发送验证码',
      dialogForm: {
        code: null,
        password: null
      },
      rules: {
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入资金密码", trigger: "blur" }
        ]
      },
      validBtnDisabled: false,
      dialogOrderId: null,
      dialogTitle: null,
      dialogType: null // 1: 确认订单  2: 付尾款
    }
  },
  beforeDestroy(){
    if(this.timeOut){
      clearInterval(this.timeOut)
      this.timeOut = null
    }
  },
  mounted(){
    // this.timeClick();
  //  this.getRights();
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch: {
    'orderItem.pickResourcesId': {
      handler(val, oldVal) {
        this.timeClick()
      }
    }
  },
  methods:{
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http
        .postFront(protocol.param_authn_margin_sendCaptcha)
        .then(response => {
          this.$EL_MESSAGE(response.data.message);
          const that = this;
          this.validBtnDisabled = true;
          this.setTime(60, that);
        });
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.cancelOrder = this.isHasRight('basis-trade-undoRepoOrder'); // 回购单撤销
          this.right.memberRepoOrder = this.isHasRight('basis-trade-memberRepoOrder'); // 会员操作
          this.right.firmRepoOrder = this.isHasRight('basis-trade-firmRepoOrder'); // 用户操作
     
      
        }
      })
    },
    timeClick(){
      if (this.orderItem&&this.orderItem.pickCreateTime) {
        // 20分钟的倒计时
        let endPayTime = Number(this.orderItem.pickCreateTime)+1200000
        this.countTime(endPayTime)
        this.timeOut = setInterval(() => {
          this.countTime(endPayTime)
          if(this.day==0&&this.hour=='00'&&this.min=='00'&&this.second=='00'){
            clearInterval(this.timeOut)
            this.timeOut = null
          }
        }, 1000);
      }
    },
    getToday() {
      var date = new Date();
      var day=date.getTime()
      // var year = date.getFullYear();
      // var month = date.getMonth() + 1;
      // var day = date.getDate();
      // var hour = date.getHours();
      // var min = date.getMinutes();
      // var sec = date.getSeconds();
      // [year, month, day, hour, min, sec] = [year, month, day, hour, min, sec].map(
      //   item => (item < 10 ? "0" + item : item)
      // );
      return Number(`${day}`);
    },
    countTime(endPayTime) {
      // 获取当前时间
      const date = new Date()
      const now = date.getTime()
      // 设置截止时间
      // const endDate = new Date(endPayTime) // this.curStartTime需要倒计时的日期
      // const end = endDate.getTime()
      const end = Number(endPayTime)
      // 时间差
      const leftTime = end - now
      let day = ''
      let hour = ''
      let min = ''
      let second = ''
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        second = s < 10 ? '0' + s : s
      } else {
        day = 0
        hour = '00'
        min = '00'
        second = '00'
      }
      this.day = day
      this.hour = hour
      this.min = min
      this.second = second
    },
    showConfirmOrderDialog(pickResourcesId) {
      this.dialogTitle = '确认订单';
      this.dialogOrderId = pickResourcesId;
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    showPayEndMoneyDialog(orderId) {
      this.dialogTitle = '付剩余款';
      this.dialogOrderId = orderId;
      this.dialogVisible = true;
      this.dialogType = 2;
    },  
    confirmDialog() {
      this.$refs['dialogForm'].validate(valid => {
        if (valid) {
            // 确认订单
          this.confirmOrder();
        }
      })
    },
  
    undoRepoOrder(repoType,repoId) {
      this.$confirm('确定撤销该订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        protocolFwd.param_undoRepoOrder.param.repoId = repoId
        protocolFwd.param_undoRepoOrder.param.repoStatus = 'U'
        protocolFwd.param_undoRepoOrder.param.type = repoType
        http.postFront(protocolFwd.param_undoRepoOrder).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.$emit('reLoad');
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }).catch(() => {
      });
    },
    agreeOrder(repoType,repoId) {
      this.$confirm('确定同意该回购申请吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
         if(repoType=='M'&&this.right.firmRepoOrder){
            protocolFwd.param_firmRepoOrder.param.repoId = repoId
            protocolFwd.param_firmRepoOrder.param.repoStatus = 'T'
            http.postFront(protocolFwd.param_firmRepoOrder).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
         }else if(repoType=='F'&&this.right.memberRepoOrder){
             protocolFwd.param_memberRepoOrder.param.repoId = repoId
            protocolFwd.param_memberRepoOrder.param.repoStatus = 'T'
  
            http.postFront(protocolFwd.param_memberRepoOrder).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
         }else{
           this.$EL_MESSAGE('您暂无此操作权限')
         }
      }).catch(() => {
      });
    },
    refuseOrder(repoType,repoId) {
      this.$confirm('确定拒绝该回购申请吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(repoType=='M'&&this.right.firmRepoOrder){
            protocolFwd.param_firmRepoOrder.param.repoId = repoId
            protocolFwd.param_firmRepoOrder.param.repoStatus = 'F'
            http.postFront(protocolFwd.param_firmRepoOrder).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
         }else if(repoType=='F'&&this.right.memberRepoOrder){
             protocolFwd.param_memberRepoOrder.param.repoId = repoId
            protocolFwd.param_memberRepoOrder.param.repoStatus = 'F'
            http.postFront(protocolFwd.param_memberRepoOrder).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
         }else{
             this.$EL_MESSAGE('您暂无此操作权限')
         }
      }).catch(() => {
      });
    },
    viewDetails(id,pickResourcesId) {
      this.$refs.orderDetails.showDialog(id,pickResourcesId);
    },
    goSupplementary(id) {
      this.$refs.supplementaryDialog.showDialog(id);
    },
    reLoadData() {
      this.$emit('reLoad');
    },
    buyBackOrder(orderId,canWeight) {
       let regAmount=/^\d+(\.\d{0,2})?$/;
      if(this.userType === 'buyer'){
            this.$prompt('请输入回购重量', '回购申请', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(({ value }) => {
            console.log(value)
            if(value==null||value==''){
              this.$EL_MESSAGE('请输入回购重量');
              return
            }
            else if(!regAmount.test(value)||Number(value)<=0){
              console.log(value)
               this.$EL_MESSAGE('重量必须是正数且不能超过两位小数');
               return
            }else if(Number(canWeight)<Number(value)){
              console.log(value)
              this.$EL_MESSAGE('不能超过可回购重量');
              return
            }
            protocolFwd.param_repoOrderForFirm.param.orderId = orderId
            protocolFwd.param_repoOrderForFirm.param.repoWeight = value
            http.postFront(protocolFwd.param_repoOrderForFirm).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
          }).catch(() => {
          });
        }else{
          if(this.right.bargainingHangOrders){
             this.$prompt('请输入回购重量', '回购申请', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(({ value }) => {
            console.log(value)
             if(value==''){
              this.$EL_MESSAGE('请输入回购重量');
              return
            }
            else if(!regAmount.test(value)||Number(value)<=0){
               this.$EL_MESSAGE('重量必须是正数且不能超过两位小数');
               return
            }else if(Number(canWeight)<Number(value)){
              this.$EL_MESSAGE('不能超过可回购重量');
              return
            }
           
            protocolFwd.param_repoOrderForMember.param.orderId = orderId
            protocolFwd.param_repoOrderForMember.param.repoWeight = value
            http.postFront(protocolFwd.param_repoOrderForMember).then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit('reLoad');
              } else {
                this.$EL_MESSAGE(message);
              }
            });
          }).catch(() => {
          });
          }else{
             this.$EL_MESSAGE('暂无此权限！');
          }
        }
     
    },
    transferOrder(id) {
      this.$refs.transferDialog.showDialog(id)
    },
    handleClose() {
      this.dialogTitle = null;
      this.dialogOrderId = null;
      this.dialogVisible = false;
      this.dialogType = null;
      this.dialogForm = {
        code: null,
        password: null
      }
    }
  }
}
</script>
<style scoped lang="scss">
#generalOrderCell{
  line-height: 35px;
}
#generalOrderCell .generalOrderCellItem {
  text-align: center;
}
#generalOrderCell .cellOne{
  margin-top: 10px;
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
#generalOrderCell .cellTwo{
  border-top: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
#generalOrderCell .cellThree{
  border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
}
.redColor{
  color: rgb(210, 29, 39);
}
.cellMethod{
  padding-right: 5px;
  float: right;
  text-align: right;
}
.viewDetailsButton {
  // margin-right: 30px;
  text-align: right;
}
.espli{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right:10px;
  box-sizing: border-box;
}
</style>
