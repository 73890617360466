<template>
  <el-dialog
  v-dialogDrag
    title="订单详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
      <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">回购编号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.repoId }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单编号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderId }}</p>
            </el-col>
          </el-row>
        </el-col>
      
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">委托时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.createTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
    
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12" v-for="(item,index) of form.specificationsName" :key="index">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
      
       
       
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">基差价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.basisPrice }}元/{{form.weightUnitName}}</p>
            </el-col>
          </el-row>
        </el-col> 
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.repoWeight }}{{form.weightUnitName}}</p>
            </el-col>
          </el-row>
        </el-col>
    
       
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.storageName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">总价格：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{(Number(form.basisPrice) * Number(form.repoWeight)).toFixed(2) }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">初始挂单方：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.memberEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
     
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">回购方：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.firmEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'" class="text">{{ form.repoStatus | repoStatusFormate  }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props:{
    orderType: String
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        // warehousId: null,
        // price: null
      },
    };
  },
  methods: {
    showDialog(repoId) {
      this.dialogVisible = true;
      this.getDetails(repoId);
    },
    getDetails(repoId) {
      protocolFwd.param_queryBasisQueryRepoOrderLd.param.repoId = repoId
      protocolFwd.param_queryBasisQueryRepoOrderLd.param.page = 0
      protocolFwd.param_queryBasisQueryRepoOrderLd.param.size = 1
      protocolFwd.param_queryBasisQueryRepoOrderLd.param.repoStatus = null
      protocolFwd.param_queryBasisQueryRepoOrderLd.param.repoType = null
 
      http.postFront(protocolFwd.param_queryBasisQueryRepoOrderLd).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.content[0].specificationsName = this.attributeTrans(value.content[0].specificationsName)
            this.form = value.content[0];
            for (let i in this.form) {
              if (!this.form[i]&&this.form[i]!=0) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.viewDetails .text {
  text-align: left;
}
.proPictureImg {
  max-width: 80%;
}
</style>