<template>
  <!-- <div>通用订单</div> -->
  <div id="generalOrder">
    <el-row>
      <el-col :span="23">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" text-color="#000" active-text-color="rgb(210, 29, 39)">
          <el-menu-item index="1">全部</el-menu-item>
          <el-menu-item index="2">已提交</el-menu-item>
          <el-menu-item index="3">待同意</el-menu-item>
          <el-menu-item index="4">已同意</el-menu-item>
          <el-menu-item index="5">未同意</el-menu-item>
          <el-menu-item index="6">已撤销</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="23" class="darkColor">
        <el-col :span="22">
          <el-col :span="3"><p class="tableHeaderItem">商品分类</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">基差价</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">数量</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">仓库</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">重量</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">状态</p></el-col>
        </el-col>
      </el-col>
      <el-col :span="23" class="orderItem" v-for="(orderItem,index) in orders" :key="index">
        <general-order-cell :orderItem="orderItem" @reLoad="getSearchDatas" :userType="userType" :gzFlag="gzFlag" :orderType="orderType" :right="right"></general-order-cell>
      </el-col>
    </el-row>
    <div v-if="orders.length == 0" class="noData">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script>
import generalOrderCell from './generalOrderCell'
export default {
  props:{
    orders: Array,
    userType: String,
    gzFlag: String,
    orderType: String
  },
  components:{
    'general-order-cell':generalOrderCell
  },
  data(){
    return{
      activeIndex: '1',
      searchForm: {
        repoStatus: null,
        firmRepoStatus: null,
        repoType: null

      },
         right: {
        firmRepoOrder: false,
        memberRepoOrder: false,
        cancelOrder: false,
       
      },
    }
  },
  mounted(){
   this.getRights();
  },
  methods:{
     getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.cancelOrder = this.isHasRight('basis-trade-undoRepoOrder'); // 回购单撤销
          this.right.memberRepoOrder = this.isHasRight('basis-trade-memberRepoOrder'); // 会员操作
          this.right.firmRepoOrder = this.isHasRight('basis-trade-firmRepoOrder'); // 用户操作
     
      
        }
      })
    },
    resetSearch() {
      this.searchForm = {
        repoStatus: null,
      }
      this.getSearchDatas();
    },
  // 回购状态：W：已提交MU：会员撤销FU:用户撤销T：已完成FR:用户拒绝MR：会员拒绝
   handleSelect(tab) {
      switch(tab) {
        case '1':
          // 全部
          this.searchForm.repoStatus = null
          this.searchForm.repoType = null
          break;
        case '2':
          // 已提交
          this.searchForm.repoStatus = ['W']
          this.searchForm.repoType = this.gzFlag
          break;
        case '3':
          // 待同意
          this.searchForm.repoStatus = ['W']
          this.searchForm.repoType = this.gzFlag=='M'?'F':'M'
          break;
        case '4':
          // 已同意
          this.searchForm.repoStatus = ['T']
          this.searchForm.repoType = null
          break;
        case '5':
          // 未同意
          this.searchForm.repoStatus = ['FR','MR']
          this.searchForm.repoType = null
          break;
        case '6':
          // 已取消
          this.searchForm.repoStatus = ['MU','FU']
          this.searchForm.repoType = null
          break;
        
      }
      this.getSearchDatas();
    },
    getSearchDatas(){
      this.$emit('reLoad', this.searchForm);
    }
  }
}
</script>
<style scoped lang="scss">
.headerText{
  padding-left: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #9999a2;
}
.darkColor{
  margin-top: 5px;
  padding: 5px 0;
  background: rgba(5, 64, 106, 1);
}
.tableHeaderItem{
  color: #fff;
  text-align: center;
  line-height: 30px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>